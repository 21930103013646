<template>

            <div  v-if = "showSignature">
                      <v-dialog persistent v-model="showSignature" width="90%" >
                            <SignaturePad  
                               @SignatureCanceled = "cancelSignature"
                               @SignatureUpdated = "(e) => { closeSignature(e); }">
                            </SignaturePad>
                      </v-dialog>
            </div>

            <div v-else-if = "showSpinner" class="spinner">

              <v-card class="mx-auto" min-width="300">
                 <v-card-title class="primary white--text">Render PDF Review</v-card-title>
                 <v-container style="height: 200px;">
                  <v-row
                    class="fill-height"
                    align-content="center"
                    justify="center"
                  >

                    <v-col cols="12" class="text-subtitle-1 text-center">
                      <span>
                        <v-icon>mdi-file-pdf</v-icon>
                           Rendering the PDF...
                      </span>  
                    </v-col>
                    
                    <v-col cols="8">
                      <v-progress-linear
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"
                      ></v-progress-linear>
                    </v-col>

                  </v-row>
                 </v-container>
              </v-card>
            </div>
            
            <div v-else style="-webkit-overflow-scrolling: touch; overflow-x: hidden; overflow-y: auto; position: absolute; top: 0; left: 0; right: 0; bottom: 0;">
               <iframe :src="viewerUrl" allowfullscreen webkitallowfullscreen style="text-align:center;width:100%;height:98%;border:none;"></iframe>
            </div>

</template>

<script>
 
 import {store} from '../../main.js'

 export default {
     name         : "FormReview", 
     data() {
        return {
                viewerUrl      : "",
                showSpinner    : false,    
                showSignature  : true,    //show the signature modal initially
               }
     },
     methods   :    {

                        closeSignature( signatureObject) {
                           this.showSignature  = false;
                           this.showSpinner    = true;
                           this.loadFormReview( signatureObject );
                        },
                        cancelSignature() {
                           this.showSignature  = false;
                           this.$router.back();

                        },
                        loadFormReview(signatureObject) {
                             
                              var params    =  (this.$route.params) ? this.$route.params : JSON.parse( localStorage.getItem("equalResponseFormReview") );  
                              let  self     =  this;

                              // add the signature object 9/18/21
                              params.formReviewData.signature = signatureObject; 

                              //////////////////////////////////////////////////////////////////////////////
                              // reset any previous stored FormreviewResponse (used to upload)            //
                              //////////////////////////////////////////////////////////////////////////////
                              store.resetFormReviewResponse();

                              this.$http.post( params.formReviewURL, params.formReviewData ) 
                              .then(  (response ) => {

                                        var formReviewResponse  = response.data;
                                        if ( formReviewResponse) {   
 
                                             // add the formname to the response object
                                             formReviewResponse['formName'] = params.formReviewName;
 
                                             self.showSpinner           =  false;
                                             self.viewerUrl             =  formReviewResponse.viewerUrl;

                                             store.saveFormReviewResponse( formReviewResponse );
                           
                                          }

                              })
                              .catch( (e) => {
                                     alert(e.message);
                              });
                    }
     }
  };

</script>


<style scoped>

     .spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80vh
     }


    @media screen and (max-width:414px) {
       .lowres2 {
          transform-origin: top left;
          transform: scale(0.50);
          background-color: blue;
        }
    }
    
    @media screen and (max-width:375px) {
       .lowres2 {
          transform-origin: top left;
          transform: scale(0.60);
          background-color: red;
        }
    }
    
     .lowres {
        transform: scale(0.68);
      }

     .lowresAndOrigin {
        transform: scale(0.68);
        transform-origin: top left;
      }

     #outerdiv {
       left       : 0 !important;
       margin-left: 0 !important;
     }

     iframe{
            width:100%;
            height:100vh;
            overflow:hidden;
            margin:0px;
            padding:0px;
            border:none;
      }

      embed{
            width:100%;
            height:80vh;
            overflow:hidden;
            margin:0px;
            padding:0px;
            border:none;
      }

</style>
